<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增产品"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="产品名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入产品名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="型号">
          <a-input
            v-decorator="['product_model', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入型号' },
                { max: 30, message: '最多30个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="项目代码">
          <a-input
            v-decorator="['code', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入项目代码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="拼音码">
          <a-input
            v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入拼音码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="['product_type']"
            :loading="typeOptions.length === 0"
            allow-clear
            @change="getType"
          >
            <a-select-option
              v-for="type in typeOptions"
              :key="type.slug"
              :value="type.slug"
            >
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文明棺" v-if="productType === 'coffin'">
          <a-checkbox
            v-decorator="['is_civilized_coffin', {
              initialValue: false,
            }]"
          />
        </a-form-item>
        <a-form-item label="遗体SPA" v-if="productType === 'derivation'">
          <a-checkbox
            v-decorator="['is_spa', {
              initialValue: false,
            }]"
          />
        </a-form-item>
        <a-form-item label="销售范围">
          <a-select
            placeholder="请选择销售范围"
            allow-clear
            mode="multiple"
            v-decorator="['sales_scope',{
              initialValue: ['order'],
            }
            ]"
          >
            <a-select-option key="0" value="order">
              订购产品
            </a-select-option>
            <a-select-option key="1" value="other_items">
              其他项目
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="惠民赠送">
          <a-select
            placeholder="请选择是否惠民赠送"
            v-decorator="['benefit_the_people',{
              initialValue: 'false',
            }]"
          >
            <a-select-option key="true" value="true">
              是
            </a-select-option>
            <a-select-option key="false" value="false">
              否
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="产生任务">
          <a-select
            placeholder="请选择产生任务"
            allow-clear
            mode="multiple"
            v-decorator="['tasks']"
          >
            <a-select-option key="0" value="distribution">
              配货任务
            </a-select-option>
            <a-select-option key="1" value="dispatch">
              花房派工
            </a-select-option>
            <a-select-option key="2" value="arrangement">
              布置灵堂
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="销售渠道">
          <a-select
              placeholder="请选择销售渠道"
              allow-clear
              mode="multiple"
              v-decorator="['sales_channel', {
              initialValue: ['web','mini_program','touch'],
              rules: [
                { required: true, message: '请选择销售渠道' },
              ]
            }]"
          >
            <a-select-option
                v-for="sale in saleChannelOptions"
                :key="sale.slug"
                :value="sale.slug"
            >
              {{ sale.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否回传到穿衣间">
          <a-checkbox
            v-decorator="['to_dress_room']"
          />
        </a-form-item>
        <a-form-item label="产品图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >上传的第一张图默认为产品缩略图，支持png/jpg图片格式，建议尺寸宽高比180:180px，最多10张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="10"
            :multiple="true"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="产品介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 300, message: '最多300个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import {getProductSaleChannel, getProductType} from '@/api/product_type'
import { createSystemProduct } from '@/api/product'

export default {
  name: 'NewSystemProduct',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'system_product' }),
      typeOptions: [],
      saleChannelOptions: [],
      submitting: false,
      isAddWatermark: false,
      productType: '', // 所选的产品类型
      attachments: [] // 设备图片
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.getTypeOptions()
    this.getSaleChannelOptions()
  },
  methods: {
    getType(e) {
      this.productType = e
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    },
    getSaleChannelOptions() {
      getProductSaleChannel().then((res) => {
        if (res.code === '0') {
          this.saleChannelOptions = res.data
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          createSystemProduct(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
